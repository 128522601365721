<template>
  <div class="book">
    <el-card class="book_card">
      <el-form v-model="queryInfo">
        <el-form-item label="类别：">
          <el-radio-group v-model="queryInfo.bookType" @change="typeChange">
            <el-radio-button label>不限</el-radio-button>
            <el-radio-button
              v-for="(item, index) in options"
              :key="index"
              :label="item.codeName"
            ></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类别：">
          <el-radio-group v-model="queryInfo.tag" @change="typeChange">
            <el-radio-button label>不限</el-radio-button>
            <el-radio-button
              v-for="(item, index) in optionsList"
              :key="index"
              :label="item.codeName"
            ></el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="top">
        <el-input
          placeholder="请输入书籍名称"
          v-model="queryInfo.booksName"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()"
            >搜索</el-button
          >
        </el-input>
        <div class="top_oder">
          <div v-if="showWantSee" @click="wantZeo()">
            <p>按想看人数</p>
            <span>
              <em class="pime active"></em>
              <em class="pime_bottm"></em>
            </span>
          </div>
          <div v-else @click="wantone()">
            <p>按想看人数</p>
            <span>
              <em class="pime"></em>
              <em class="pime_bottm active_bottom"></em>
            </span>
          </div>
        </div>
        <el-button type="primary" @click="navToInfoAdd()">新增</el-button>
      </div>
      <div class="book_list">
        <div
          class="book_list_item"
          v-for="(item, index) in bookList"
          :key="index"
        >
          <img :src="item.images" alt="" />
          <div class="book_list_item_right">
            <div class="book_list_item_right_title">{{ item.bookName }}</div>
            <div class="book_list_item_right_ia">
              <div class="auth">{{ item.author }}</div>
              <div class="hot" v-if="item.heatValue">
                <i class="icon-jr-icon-hotspot iconfont"></i>
                {{ item.heatValue }}
              </div>
              <div class="see_want">{{ item.count }}人表示想看</div>
            </div>
            <div class="book_list_item_right_jj">{{ item.bookAbstract }}</div>
            <div class="book_list_item_right_ul">
              <div
                class="book_list_item_right_li"
                v-for="(tt, ii) in item.tag.split(',')"
                :key="ii"
              >
                {{ tt }}
              </div>
            </div>
          </div>
          <div class="book_btn">
            <el-button plain v-if="!item.isTrue" @click="releaseClick(item.id)"
              >发布书籍</el-button
            >
            <el-button type="primary" @click="releaseClick(item.id)" v-else
              >下架书籍</el-button
            >
            <el-button type="text" @click="editClick(item)">编辑</el-button>
            <el-button type="text" @click="deleteClick(item)">删除</el-button>
          </div>
        </div>
      </div>
      <div class="special_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[30, 50, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { getBookAll, deleteBook, postIsState } from "@/api/book.js";
import { codeType } from "@/api/common.js";
import { pagination } from "@/mixins/pagination";
const defaultQueryInfo = {
  bookType: "", //标签
  pageNumber: 0, // 页码
  pageSize: 30, //每页显示个数
  booksName: "", //书籍名称
  order: 0,
  tag: "",
};
export default {
  name: "Book",
  mixins: [pagination],
  data() {
    return {
      optionsList: [],
      showWantSee: true,
      options: [], //code表数据
      queryInfo: { ...defaultQueryInfo }, //书籍条件
      bookList: [], //书籍数据
    };
  },
  created() {
    this.search();
    this.tagList();
    this.getOptionsList();
  },
  methods: {
    //想看oder->1
    wantZeo() {
      this.showWantSee = !this.showWantSee;
      this.queryInfo.order = 1;
      this.search();
    },
    //想看oder -> 0
    wantone() {
      this.showWantSee = !this.showWantSee;
      this.queryInfo.order = 0;
      this.search();
    },
    //新增
    navToInfoAdd() {
      //新增
      this.$router.push({
        path: "/book/info/add",
        query: { bookType: 0 },
      });
    },
    //新建0 编辑1
    editClick(row) {
      //编辑
      this.$router.push({
        path: "/book/info/edit",
        query: { bookType: 1, id: row.id },
      });
    },
    //上下架
    async releaseClick(id) {
      const { data: res } = await postIsState([id]);
      if (res.code === 10200) {
        this.$messageSuccess("操作成功！");
        this.search();
      } else {
        this.$messageError("操作失败。");
      }
    },
    //删除单个书籍
    deleteClick(row) {
      this.$confirm(`确认删除${row.bookName}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await deleteBook({ id: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.bookName}删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
    //获取筛选标签
    async tagList() {
      const { data: res } = await codeType({ codeType: "004" });
      if (res.data) {
        this.options = res.data;
      }
    },
    //获取书籍标签
    async getOptionsList() {
      const { data: res } = await codeType({ codeType: "005" });
      if (res.data) {
        this.optionsList = res.data;
      }
    },
    //获取书籍数据
    async search() {
      const { data: res } = await getBookAll(this.queryInfo);
      if (res.data) {
        this.bookList = res.data.data;
        this.total = res.data.pageTotal;
      } else {
        this.bookList = [];
        this.total = 0;
      }
    },
    //选择标签
    typeChange() {
      this.search();
    },
  },
};
</script>

<style lang="less" scoped>
.book {
  width: 100%;

  .top {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;

    .top_oder {
      margin: 0 20px;
      display: inline-flex;
      overflow: hidden;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        p {
          color: #151515;
          font-size: 14px;
          font-weight: 400;
        }

        span {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 4px;

          .pime {
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid #a6a8ab;
          }

          .active {
            border-bottom-color: #000;
          }

          .pime_bottm {
            margin-top: 2px;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #a6a8ab;
          }

          .active_bottom {
            border-top: 4px solid #000 !important;
          }
        }
      }
    }

    /deep/ .el-radio-button__inner {
      border: none;
      border-radius: 4px !important;
      margin-right: 8px;
    }

    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #8483d0 !important;
      box-shadow: -1px 0 0 0 #8483d0;
    }

    /deep/ .el-radio-button__inner:hover {
      color: #8483d0;
    }

    /deep/ .el-input-group {
      width: 415px;
    }

    /deep/ .el-input__inner {
      border: 1px solid #8483d0;
    }

    /deep/ .seach {
      border: 1px solid #8483d0;
      border-radius: 0;
      color: #fff;
      background-color: #8483d0;
    }

    .add_special {
      margin-left: 50px;
    }

    /deep/ .el-button--primary {
      background-color: #8483d0;
      border-color: #8483d0;
      margin-left: 20px;
    }
  }

  .book_list {
    width: 100%;
    overflow: hidden;

    .see_want {
      color: #a6a8ab;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
    }

    .book_list_item {
      width: 100%;
      border-bottom: 1px solid #efefef;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      flex: row;

      img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        overflow: hidden;
        display: block;
        border-radius: 4px;
      }

      .book_list_item_right {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .book_list_item_right_title {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #151515;
          font-size: 14px;
          font-weight: 500;
        }

        .book_list_item_right_ia {
          width: 100%;
          display: flex;
          margin: 10px 0;
          flex-direction: row;

          .auth {
            color: #a6a8ab;
            font-size: 12px;
            font-weight: 400;
          }

          .hot {
            margin-left: 30px;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #fb5959;

            .icon-jr-icon-hotspot {
              color: #fb5959;
            }
          }
        }

        .book_list_item_right_jj {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          width: 100%;
          color: #151515;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5em;
        }

        .book_list_item_right_ul {
          display: flex;
          flex-direction: row;

          .book_list_item_right_li {
            margin-right: 12px;
            box-sizing: border-box;
            padding: 2px 6px;
            border-radius: 6px;
            background-color: #f6f6f6;
            color: #8483d0;
            font-size: 10px;
            font-weight: 400;
          }
        }
      }

      .book_btn {
        width: 20%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 20px;

        /deep/ .el-button--default {
          color: #8483d0;
          border-color: #8483d0;
        }

        /deep/ .el-button--default:hover {
          color: #8483d0;
          border-color: #8483d0;
        }

        /deep/ .el-button--primary {
          background-color: #8483d0;
          border-color: #8483d0;
        }

        /deep/ .el-button--text {
          color: #c4c4c4;
        }
      }
    }

    .book_list_item:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
</style>